import First from "../../../../Images/Storii01.jpg";
import Second from "../../../../Images/Storii02.jpg";
import Third from "../../../../Images/Storii03.jpg";

const storiiImages = [
  {
    title: `Storii Jaisalmer`,
    urls: First,
  },
  {
    title: `Storii Jaisalmer`,
    urls: Second,
  },
  {
    title: `Storii Jaisalmer`,
    urls: Third,
  },
];

export default storiiImages;
